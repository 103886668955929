import { useQuery } from '@apollo/client'
import { PollingInterval, usePollQueryWhileMounted } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'

export type ChartDataQueryProps = {
  poolAddress: string
  durationId: string
  timestamp: string
  value: string
  token0Ratio: string
  token1Ratio: string
  token0DurationLow: string
  token0DurationHigh: string
  token1DurationLow: string
  token1DurationHigh: string
  token0Twap: string
  token1Twap: string
}

const query = gql`
  query poolRatios($poolAddress: String!) {
    poolRatios(
      where: { poolAddress: $poolAddress, timestamp_gt: "1700438888" }
      orderBy: timestamp
      orderDirection: asc
    ) {
      poolAddress
      durationId
      timestamp
      value
      token0Ratio
      token1Ratio
      token0DurationLow
      token0DurationHigh
      token1DurationLow
      token1DurationHigh
      token0Twap
      token1Twap
    }
  }
`

export const useChartDataQuery = (poolAddress?: string, duration?: string) => {
  const { data, loading, error } = usePollQueryWhileMounted(
    useQuery(query, {
      variables: {
        poolAddress: poolAddress?.toLocaleLowerCase(),
      },
    }),
    PollingInterval.LightningMcQueen
  )

  return useMemo(() => ({ data: data?.poolRatios, loading, error }), [data, error, loading])
}
