// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Currency, Token } from '@uniswap/sdk-core'
import { ReactComponent as StarIconActive } from 'assets/imgs/star_active.svg'
import { sendEvent } from 'components/analytics'
import { StyledCloseIcon, StyledStarIcon } from 'components/Icons/StyledIcon'
import { PaddedColumn, SearchInput } from 'components/SearchModal/styleds'
import { useActiveChainId } from 'connection/useActiveChainId'
import useDebounce from 'hooks/useDebounce'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { AllPoolsProps } from 'hooks/useQueryAllPools'
import useToggle from 'hooks/useToggle'
import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { getTokenFilter } from 'lib/hooks/useTokenList/filtering'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import styled, { useTheme } from 'styled-components/macro'

import { useDefaultActiveTokens, useIsUserAddedToken, useSearchInactiveTokenLists, useToken } from '../../hooks/Tokens'
import { ThemedText } from '../../theme'
import { isAddress } from '../../utils'
import Column from '../Column'
import Row, { RowBetween } from '../Row'
import CurrencyList from './CurrencyList'
import SortCell from './CurrencyList/SortCell'
import { useSortPools } from './hooks'
import { RtarSortMethod, RtarsortMethodAtom, rtarString, TokenSortMethod, useRtarAcitons } from './state'

const ContentWrapper = styled(Column)`
  width: 100%;
  overflow: hidden;
  flex: 1 1;
  position: relative;
  border-radius: 20px;
`

const TableThBox = styled(Box)`
  width: 100%;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  grid-template-columns: 1fr 100px 1fr;
`

const TableHeaderBox = styled(TableThBox)`
  padding: 0 8px;
  .item {
    padding: 6px 0;
  }
`

const StarBox = styled(Row)`
  gap: 8px;
  justify-content: space-between;
  padding: 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  .item {
    padding: 6px;
    cursor: pointer;
  }
`

interface CurrencySearchProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: AllPoolsProps) => void
  otherSelectedCurrency?: Currency | null
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  onlyShowCurrenciesWithBalance?: boolean
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCurrencyAmount,
  onDismiss,
  isOpen,
  onlyShowCurrenciesWithBalance,
}: CurrencySearchProps) {
  const { chainId } = useActiveChainId()
  const theme = useTheme()

  const [tokenLoaderTimerElapsed, setTokenLoaderTimerElapsed] = useState(false)

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const currentString = useAtomValue(rtarString)

  const [searchQuery, setSearchQuery] = useState<string>(currentString)

  const setFilterString = useUpdateAtom(rtarString)

  const debouncedQuery = useDebounce(searchQuery, 200)
  const isAddressSearch = isAddress(debouncedQuery)
  const searchToken = useToken(debouncedQuery)
  const searchTokenIsAdded = useIsUserAddedToken(searchToken)

  useEffect(() => {
    setFilterString(debouncedQuery)
  }, [debouncedQuery, setFilterString])

  useEffect(() => {
    if (isAddressSearch) {
      sendEvent({
        category: 'Currency Select',
        action: 'Search by address',
        label: isAddressSearch,
      })
    }
  }, [isAddressSearch])

  const filteredPools = useSortPools()

  const defaultTokens = useDefaultActiveTokens(chainId)
  const filteredTokens: Token[] = useMemo(() => {
    return Object.values(defaultTokens).filter(getTokenFilter(debouncedQuery))
  }, [defaultTokens, debouncedQuery])

  // const [, balancesAreLoading] = useAllTokenBalances()

  const isLoading = Boolean(!filteredPools)

  const handleCurrencySelect = useCallback(
    (currency: AllPoolsProps) => {
      onCurrencySelect(currency)
    },
    [onCurrencySelect]
  )

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  // menu ui
  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, open ? toggle : undefined)

  const [SortMethod] = useAtom(RtarsortMethodAtom)
  const { setRtarsort } = useRtarAcitons()

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    !onlyShowCurrenciesWithBalance && (filteredTokens.length === 0 || (debouncedQuery.length > 2 && !isAddressSearch))
      ? debouncedQuery
      : undefined
  )

  // Timeout token loader after 3 seconds to avoid hanging in a loading state.
  useEffect(() => {
    const tokenLoaderTimer = setTimeout(() => {
      setTokenLoaderTimerElapsed(true)
    }, 3000)
    return () => clearTimeout(tokenLoaderTimer)
  }, [])

  return (
    <ContentWrapper>
      <PaddedColumn gap="16px">
        <RowBetween>
          <Text fontWeight={700} fontSize={18}>
            <Trans>Select a token</Trans>
          </Text>
          <StyledCloseIcon onClick={onDismiss} />
        </RowBetween>
        <Row>
          <SearchInput
            data-cy="star-search-input"
            type="search"
            placeholder={t`Search`}
            id="search-liquidity"
            autoComplete="off"
            value={searchQuery}
            onChange={({ target: { value } }) => setSearchQuery(value)}
          />
        </Row>
      </PaddedColumn>
      <StarBox>
        {Object.values(RtarSortMethod).map((x, i) => {
          if (x == RtarSortMethod.RTAR) {
            return (
              <span className="item" key={'rat' + x} onClick={() => setRtarsort(x)}>
                {x === SortMethod ? <StarIconActive /> : <StyledStarIcon />}
              </span>
            )
          } else {
            return (
              <ThemedText.TextSecondary
                className="item"
                color={x === SortMethod ? 'activeBorder' : 'textSecondary'}
                fontWeight={x === SortMethod ? 700 : 400}
                key={'rat' + x}
                onClick={() => setRtarsort(x)}
              >
                {x}
              </ThemedText.TextSecondary>
            )
          }
        })}
      </StarBox>
      <Box width="100% " p="8px 8px 0 8px">
        <TableHeaderBox>
          <ThemedText.TextTertiary fontSize={12} fontWeight={700} className="item">
            <Trans>Pairs</Trans>
          </ThemedText.TextTertiary>
          <Box display="flex" justifyContent="flex-start" alignItems="center" className="item">
            <SortCell category={TokenSortMethod.PRICE} justify="flex-start" />
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center" className="item">
            <SortCell category={TokenSortMethod.CHANGES} />
          </Box>
        </TableHeaderBox>
      </Box>
      {/* <Separator /> */}
      {searchToken && !searchTokenIsAdded ? (
        <></>
      ) : // <Column style={{ padding: '20px 0', height: '100%', width: '100%' }}>
      //   <CurrencyRow
      //     currency={searchToken}
      //     isSelected={Boolean(searchToken && selectedCurrency && selectedCurrency.equals(searchToken))}
      //     onSelect={(hasWarning: boolean) => searchToken && handleCurrencySelect(searchToken, hasWarning)}
      //     otherSelected={Boolean(searchToken && otherSelectedCurrency && otherSelectedCurrency.equals(searchToken))}
      //     showCurrencyAmount={showCurrencyAmount}
      //     eventProperties={formatAnalyticsEventProperties(
      //       searchToken,
      //       0,
      //       [searchToken],
      //       searchQuery,
      //       isAddressSearch
      //     )}
      //   />
      // </Column>
      (filteredPools && filteredPools?.length > 0) || isLoading ? (
        <div style={{ flex: '1', width: '100%' }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <CurrencyList
                height={height}
                currencies={filteredPools}
                otherListTokens={filteredInactiveTokens}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                selectedCurrency={selectedCurrency}
                fixedListRef={fixedList}
                showCurrencyAmount={showCurrencyAmount}
                isLoading={isLoading}
                searchQuery={searchQuery}
                isAddressSearch={isAddressSearch}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <Column style={{ padding: '20px', height: '100%' }}>
          <ThemedText.TextTertiary textAlign="center" mb="20px">
            <Trans>No results found.</Trans>
          </ThemedText.TextTertiary>
        </Column>
      )}
    </ContentWrapper>
  )
}
