import { Currency } from '@uniswap/sdk-core'
import { ReactComponent as FailIcon } from 'assets/imgs/confirmation/Fail.svg'
import { ReactComponent as PendingIcon } from 'assets/imgs/confirmation/pending.svg'
import { ReactComponent as SuccessIcon } from 'assets/imgs/confirmation/success.svg'
import { ReactComponent as PapersIcon } from 'assets/svg/papers-text.svg'
import { LoaderV3 } from 'components/Icons/LoadingSpinner'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { useUnmountingAnimation } from 'hooks/useUnmountingAnimation'
import { useRef } from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components/macro'

export const LogoContainer = styled.div`
  min-height: 48px;
  min-width: 48px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  .bg {
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px 24px 50% 50%;
  }
  .success-bg {
    background: ${({ theme }) => theme.successBg};
  }
  .fail-bg {
    background: ${({ theme }) => theme.failBg};
  }
  .pending-bg {
    background: ${({ theme }) => theme.pendingBg};
  }
`

const fadeIn = keyframes`
  from { opacity: 0;}
  to { opacity: 1;}
`
const fadeAndScaleIn = keyframes`
  from { opacity: 0; transform: scale(0); }
  to { opacity: 1; transform: scale(1); }
`
const fadeInAnimation = css`
  animation: ${fadeIn} ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`
const fadeAndScaleInAnimation = css`
  animation: ${fadeAndScaleIn} ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0;  }
`
const fadeAndScaleOut = keyframes`
  from { opacity: 1; transform: scale(1); }
  to { opacity: 0; transform: scale(0); }
`
const fadeOutAnimation = css`
  animation: ${fadeOut} ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`
const fadeAndScaleOutAnimation = css`
  animation: ${fadeAndScaleOut} ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`

export enum AnimationType {
  EXITING = 'exiting',
}

const FadeWrapper = styled.div<{ $scale: boolean }>`
  transition: display ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`},
    transform ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
  ${({ $scale }) => ($scale ? fadeAndScaleInAnimation : fadeInAnimation)}

  &.${AnimationType.EXITING} {
    ${({ $scale }) => ($scale ? fadeAndScaleOutAnimation : fadeOutAnimation)}
  }
`

// eslint-disable-next-line import/no-unused-modules
export function FadePresence({
  children,
  className,
  $scale = false,
  ...rest
}: {
  children: React.ReactNode
  className?: string
  $scale?: boolean
}) {
  const ref = useRef<HTMLDivElement>(null)
  useUnmountingAnimation(ref, () => AnimationType.EXITING)
  return (
    <FadeWrapper ref={ref} className={className} $scale={$scale} {...rest}>
      {children}
    </FadeWrapper>
  )
}

const CurrencyLoaderContainer = styled(FadePresence)<{ asBadge: boolean }>`
  z-index: 2;
  border-radius: 50%;
  transition: all ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
  position: absolute;
  height: ${({ asBadge }) => (asBadge ? '20px' : '100%')};
  width: ${({ asBadge }) => (asBadge ? '20px' : '100%')};
  bottom: ${({ asBadge }) => (asBadge ? '-4px' : 0)};
  right: ${({ asBadge }) => (asBadge ? '-4px' : 0)};
  outline: ${({ theme, asBadge }) => (asBadge ? `2px solid ${theme.background}` : '')};
`

const RaisedCurrencyLogo = styled(CurrencyLogo)`
  z-index: 1;
`

export function CurrencyLoader({ currency, asBadge = false }: { currency?: Currency; asBadge?: boolean }) {
  return (
    <CurrencyLoaderContainer asBadge={asBadge} data-testid={`pending-modal-currency-logo-${currency?.symbol}`}>
      <RaisedCurrencyLogo currency={currency} size="100%" />
    </CurrencyLoaderContainer>
  )
}

const PinkCircle = styled(FadePresence)`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.userThemeColor};
  z-index: 1;
`

export function PaperIcon() {
  return (
    <PinkCircle>
      <PapersIcon />
    </PinkCircle>
  )
}

const LoadingIndicator = styled(LoaderV3)`
  stroke: ${({ theme }) => theme.textTertiary};
  fill: ${({ theme }) => theme.textTertiary};
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  top: -4px;
  left: -4px;
  position: absolute;
`

export function LoadingIndicatorOverlay() {
  return (
    <FadePresence>
      <LoadingIndicator />
    </FadePresence>
  )
}

function ConfirmedIcon({ className }: { className?: string }) {
  const theme = useTheme()
  return (
    <FadePresence $scale>
      {/* <svg
        data-testid="confirmed-icon"
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM37.7466 22.1997L25.2933 34.6263C24.9199 35.0263 24.4133 35.2131 23.8799 35.2131C23.3733 35.2131 22.8666 35.0263 22.4666 34.6263L16.2533 28.4131C15.48 27.6398 15.48 26.3596 16.2533 25.5863C17.0266 24.8129 18.3066 24.8129 19.08 25.5863L23.8799 30.3864L34.92 19.373C35.6933 18.573 36.9733 18.573 37.7466 19.373C38.52 20.1464 38.52 21.3997 37.7466 22.1997Z"
          fill={theme.accentSuccess}
        />
      </svg> */}
      <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 922">
          <circle id="Ellipse 274" cx="51" cy="51" r="51" fill="#00D09C" fillOpacity="0.2" />
          <circle id="Ellipse 275" cx="51" cy="51" r="42" fill="#00D09C" fillOpacity="0.2" />
          <circle id="Ellipse 273" cx="51" cy="51" r="32" fill="#00D09C" />
          <path
            id="Vector 395 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.1064 42.136L45.5442 70.3015L30.8064 54.5047L35.1936 50.4117L45.7234 61.6982L69.8935 37.8638L74.1064 42.136Z"
            fill="white"
          />
        </g>
      </svg>
    </FadePresence>
  )
}

export const AnimatedEntranceConfirmationIcon = styled(ConfirmedIcon)`
  height: 102px;
  width: 102px;
`

function FailedIcon({ className }: { className?: string }) {
  const theme = useTheme()
  return (
    <FadePresence $scale>
      <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="51" cy="51" r="51" fill="#D01900" fillOpacity="0.2" />
        <circle cx="51" cy="51" r="42" fill="#D01900" fillOpacity="0.2" />
        <circle cx="51" cy="51" r="32" fill="#E44E1E" />
        <path d="M38 39L64 65" stroke="white" strokeWidth="6" strokeLinecap="round" />
        <path d="M64 39L38 65" stroke="white" strokeWidth="6" strokeLinecap="round" />
      </svg>
    </FadePresence>
  )
}

export const AnimatedEntranceSuccessedIcon = styled(SuccessIcon)`
  /* height: 102px;
  width: 102px; */
`

export const AnimatedEntranceFailedIcon = styled(FailIcon)`
  /* height: 102px;
  width: 102px; */
`

export const AnimatedPendingIcon = styled(PendingIcon)`
  /* height: 102px;
  width: 102px; */
`
