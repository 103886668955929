import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'

import { useTradeReader } from './useContract'

export default function useEstimateSwap(spotPool: string, zeroForOne: boolean, amountSpecified?: any) {
  const readerContract = useTradeReader()


  console.log({spotPool,zeroForOne,amountSpecified})
  const { result: emData } = useSingleCallResult(readerContract, 'estimateSwap', [
    spotPool,
    zeroForOne ? 1 : 0,
    amountSpecified,
  ])
  console.log({emData})
  // return useMemo(() => {
  //   if (!emData) return false
  //   return emData?.resv / 1000000 >= 0.8
  // }, [emData])
  return false
}
