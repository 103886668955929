import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { formatCurrencyAmount, NumberType } from '@uniswap/conedison/format'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { ButtonConfirmed } from 'components/Button'
import Checkbox from 'components/Checkbox'
import Column, { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { useDynamicApprove } from 'components/DynamicApprove'
import { LoadingDataBubble } from 'components/Loading'
import { RateToggle4 } from 'components/RateToggle'
import Row, { RowBetween } from 'components/Row'
import SettingsTab from 'components/Settings'
import { Input } from 'components/Settings/Input'
import { TradeTypes } from 'components/swap/SwapDetails'
import { TabPeriod } from 'components/swap/types'
import {
  ConfirmationModalContent2222,
  TransactionConfirmationModal22222,
} from 'components/TransactionConfirmationModal'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import useAutoSlippageTolerance from 'hooks/useAutoSlippageTolerance'
import { useOrderBookContract, useTradeRouter } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useEthPriceUSD, useGraphTokenListMAP } from 'hooks/useQueryLiquidity'
import { SwapSection } from 'pages/Swap/Styled'
import { useCallback, useMemo, useState } from 'react'
import { useTradeSWAPState } from 'state/positionSwap/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN, formFeeWei, fromSqrt96Wei, fromWei, toSqrt96 } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { handlerError } from 'utils/formatError'

import { useEstimateDecrease, useEstimateDecreaseL, usePositonDetail } from '../Hooks'
import { TradePositionProps } from '../types'
import { EditSwapSection } from './EditPositionModal'

interface ClosePositionModalProps {
  isOpen: boolean
  onDismiss: () => void
  position?: TradePositionProps
}

const CloseWrapper = styled.div`
  width: 364px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
  }
`

const CloseInfo = styled.div`
  flex: 1;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 16px;
  .infoItem {
    padding: 8px 0;
  }
  .border-bottom {
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.swapSectionBorder};
  }
  .border-solid {
    border-bottom-style: solid;
  }
  .border-dashed {
    border-bottom-style: dashed;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
  }
`

const CheckWapper = styled(Row)`
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
`

const CloseSwapSection = styled(SwapSection)`
  border-radius: 12px;
  margin-top: 8px;
`

const EditFeeBox = styled(Column)`
  width: 100%;
  gap: 8px;
  align-items: flex-start;
`

const StyledInput = styled(Input)`
  text-align: left;
  font-size: 36px;
  font-weight: 700;
  font-family: 'JetBrainsMono custom';
  line-height: 44px;
  font-variant: small-caps;
`

const ModalContent = ({
  position,
  setAttemptingTxn,
  setTxHash,
  setTxError,
}: {
  position?: TradePositionProps
  setAttemptingTxn: any
  setTxHash: any
  setTxError: any
}) => {
  const {
    currentSqrtRatioX96,
    slippageTolerance,
    tradePool,
    traderFee,
    token0,
    token1,
    tokenRatio,
    positonsymbol: symbol,
    isToken0,
  } = usePositonDetail(position)
  // console.log('position', position)
  const { account, chainId } = useActiveChainId()
  const { isToken0: UIistoken0 } = useTradeSWAPState()
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const [isCheck_leverage, setIsCheck_leverage] = useState(true)
  const [isCheck_slippage, setIsCheck_slippage] = useState(false)
  const [receiveETH, setReceiveETH] = useState(false)

  const isShowEthSwitch = useMemo(() => {
    if (position?.long0) {
      if (token0?.symbol == 'WETH') return true
    } else {
      if (token1?.symbol == 'WETH') return true
    }
    return false
  }, [position, token0, token1])
  const currencyA = useCurrency(position?.long0 ? position?.token1 : position?.token0)

  const currency0 = useCurrency(position?.token0)
  const currency1 = useCurrency(position?.token1)

  const feeCurrency = position?.long0 ? currency0 : currency1
  const types = TabPeriod.Market
  const [tabPeriod, setTabPeriod] = useState<TabPeriod>(types)

  const addTransaction = useTransactionAdder()
  const tradeRouter = useTradeRouter()
  const orderbook = useOrderBookContract()
  const colDecimals = position?.long0 ? currency0?.symbol : currency1?.symbol
  const decimals = position?.long0 ? currency1?.decimals : currency0?.decimals

  const currentAmount = useMemo(() => {
    if (!currencyA || !position) return
    return CurrencyAmount.fromRawAmount(currencyA, position.size.toString())
  }, [currencyA, position])
  const { inputAmount, inputError, handleUserInput, isInputValid } = useInputAmount(
    currencyA ?? undefined,
    currentAmount
  )
  const netValueFees = useMemo(() => {
    if (!traderFee || !position || !decimals) return
    const amount = inputAmount.amount?.numerator.toString()
    if (!amount || !position || amount == '0') return
    const positionFeeint = formFeeWei(traderFee.positionFee)

    const positionSize = fromWei(amount, decimals)

    const positionFee = positionSize.times(positionFeeint)
    const borrowFee = fromWei(position.fundingFee.toString(), decimals)
    return {
      borrowFee: borrowFee.toFixed(),
      Positionfees: positionFee.toFixed(),
    }
  }, [decimals, inputAmount.amount?.numerator, position, traderFee])
  const { inputAmount: feeamount, handleUserInput: setfeeAmount } = useInputAmount(feeCurrency)

  const [triggerPrice, setTriggerPrice] = useState<any>('')

  const decreaseParms = useMemo(() => {
    let amount = inputAmount.amount?.numerator.toString()
    if (!position || !amount || !netValueFees) return

    const leverage = BN(position.size.toString()).div(position.collateral.toString())
    let collateral = '0'
    if (isCheck_leverage) {
      if (BN(amount).gte(position.size.toString())) {
        collateral = position.collateral.toString()
        amount = position.size.toString()
      }
      collateral = BN(amount).div(leverage).toFixed(0)
    }
    return {
      size: amount,
      collateral,
    }
  }, [inputAmount.amount?.numerator, isCheck_leverage, netValueFees, position])

  const { result: estimateDecrease, loading: estimateDecreaseLoading } = useEstimateDecrease(
    position?.long0,
    position?.pool,
    decreaseParms?.size,
    decreaseParms?.collateral
  )

  const { result: estimateDecreaseL, loading: estimateDecreaseLLoading } = useEstimateDecreaseL(
    position?.long0,
    position?.pool,
    decreaseParms?.size
  )

  const token = position?.long0 ? token0 : token1
  const calctoken = position?.long0 ? token1 : token0

  const initialCollateral = useMemo(() => {
    if (!position || !token0 || !token1 || !decimals) return
    return fromWei(position.collateral.toString(), decimals).toString()
  }, [decimals, position, token0, token1])

  const Receive = useMemo(() => {
    const amount = inputAmount.amount?.numerator.toString()
    if (!position || !amount || amount == '' || !token0 || !token1 || !traderFee || !tokenRatio || !estimateDecreaseL)
      return

    const decimals = position.long0 ? token0.decimals : token1.decimals

    const calcReceive = fromWei(estimateDecreaseL?.payBack.toString(), decimals)
    const currentReceive = position.long0
      ? calcReceive.div(tokenRatio.invert().toSignificant())
      : calcReceive.div(tokenRatio.toSignificant())

    return {
      calcReceive: calcReceive.toFixed(4),
      currentReceive: currentReceive.toFixed(4),
    }
  }, [estimateDecreaseL, inputAmount.amount?.numerator, position, token0, token1, tokenRatio, traderFee])

  const leverage = useMemo(() => {
    if (!position) return
    return BN(position.size.toString()).div(position.collateral.toString()).toFixed(2)
  }, [position])

  const handleMaxTab = (value: string) => {
    if (!currentAmount || !currencyA) return
    const amount = BN(currentAmount.toSignificant()).multipliedBy(value).toFixed(currencyA?.decimals, 1)
    handleUserInput(amount)
  }

  const closePosition = useCallback(async () => {
    let amount = inputAmount.amount?.numerator.toString()
    if (!tradeRouter || !account || !amount || !position || !tradePool || !currentSqrtRatioX96 || !token0 || !token1)
      return
    let collateral = '0'
    setAttemptingTxn(true)
    if (isCheck_leverage) {
      if (!estimateDecreaseL) return
      if (BN(amount).gte(position.size.toString())) {
        collateral = position.collateral.toString()
        amount = position.size.toString()
      }
      collateral = estimateDecreaseL?.keepLevColDelta.toString()
    }
    let scurrentsqrtRatioX96: any
    const cur = fromSqrt96Wei(currentSqrtRatioX96.toString(), token0.decimals, token1.decimals).toString()

    if (position.long0) {
      scurrentsqrtRatioX96 = BN(cur)
        .multipliedBy(BN(1).minus(BN(slippageTolerance).dividedBy(100)))
        .div(BN(10).pow(token0.decimals))
        .multipliedBy(BN(10).pow(token1.decimals))
        .toString()
    } else {
      scurrentsqrtRatioX96 = BN(cur)
        .multipliedBy(BN(1).plus(BN(slippageTolerance).dividedBy(100)))
        .div(BN(10).pow(token0.decimals))
        .multipliedBy(BN(10).pow(token1.decimals))
        .toString()
    }
    console.log({ receiveETH })
    try {
      const response = await tradeRouter.decreasePosition(
        account,
        tradePool,
        collateral,
        amount,
        position.long0,
        receiveETH, //TODO: eth
        toSqrt96(scurrentsqrtRatioX96).toFixed(0)
      )

      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLOSE_POSITION,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('error', error)
    }
  }, [
    receiveETH,
    account,
    addTransaction,
    currentSqrtRatioX96,
    estimateDecreaseL,
    inputAmount.amount?.numerator,
    isCheck_leverage,
    position,
    setAttemptingTxn,
    setTxHash,
    slippageTolerance,
    token0,
    token1,
    tradePool,
    tradeRouter,
  ])

  const closeOrder = useCallback(async () => {
    const amount = inputAmount.amount?.numerator.toString()
    if (
      !orderbook ||
      !position ||
      !feeamount.amount?.numerator.toString() ||
      !triggerPrice ||
      !amount ||
      !leverage ||
      !currentSqrtRatioX96 ||
      !token0 ||
      !token1
    )
      return

    setAttemptingTxn(true)

    const shouldWarp = false

    let price = BN(triggerPrice).div(BN(10).pow(token0.decimals)).multipliedBy(BN(10).pow(token1.decimals)).toString()

    if (!UIistoken0) {
      price = BN(1).div(price).toFixed()
    }
    const triggerAboveThreshold = BN(price).gt(fromSqrt96Wei(currentSqrtRatioX96.toString()))

    try {
      const response = await orderbook.createDecreaseOrder(
        position?.pool,
        feeamount.amount?.numerator.toString(),
        BN(amount).div(leverage).toFixed(0),
        amount,
        toSqrt96(price).toFixed(0),
        position.long0,
        triggerAboveThreshold,
        shouldWarp
      )

      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLOSE_POSITION,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      console.log('error', error)
    }
  }, [
    UIistoken0,
    addTransaction,
    currentSqrtRatioX96,
    feeamount.amount?.numerator,
    inputAmount.amount?.numerator,
    leverage,
    orderbook,
    position,
    setAttemptingTxn,
    setTxHash,
    token0,
    token1,
    triggerPrice,
  ])

  const handlerClose = () => {
    tabPeriod == TabPeriod.Limit ? closeOrder() : closePosition()
  }

  const tokenPrice = useGraphTokenListMAP()
  const ethPrice = useEthPriceUSD()
  const { DynamicApprove, isApproved } = useDynamicApprove([feeamount.amount], orderbook?.address)

  const inputUsdPrice = useMemo(() => {
    if (!tokenPrice || !ethPrice || !currencyA || tokenPrice[currencyA.wrapped.address]) return
    const token = tokenPrice?.[currencyA.wrapped.address.toLocaleLowerCase()]
    const currentPrice = BN(token?.derivedETH || 0)
      .times(inputAmount.value)
      .times(ethPrice)
      .toFixed(4)
    return currentPrice
  }, [currencyA, ethPrice, inputAmount.value, tokenPrice])

  const markPrice = useMemo(() => {
    if (!position || !currentSqrtRatioX96) return
    const cur = fromSqrt96Wei(currentSqrtRatioX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [currentSqrtRatioX96, isToken0, position, token0?.decimals, token1?.decimals])
  const EntryPrice = useMemo(() => {
    if (!position) return
    const cur = fromSqrt96Wei(position.sqrtPriceX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [isToken0, position, token0?.decimals, token1?.decimals])
  const LiqPrice = useMemo(() => {
    if (!position) return
    const cur = fromSqrt96Wei(position.liqSqrtPriceX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [isToken0, position, token0?.decimals, token1?.decimals])

  const NextLiqPrice = useMemo(() => {
    if (!estimateDecrease) return
    const cur = fromSqrt96Wei(estimateDecrease.decLiqPrice.toString(), token0?.decimals, token1?.decimals)
    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [estimateDecrease, isToken0, token0?.decimals, token1?.decimals])

  // const Openpnl = useMemo(() => {
  //   if (!markPrice || !EntryPrice || !position) return
  //   if (isToken0) {
  //     if (position.long0) {
  //       return BN(markPrice)
  //         .minus(EntryPrice)
  //         .dividedBy(EntryPrice)
  //         .multipliedBy(fromWei(position.size.toString(), decimals).toString())
  //         .toString()
  //     } else {
  //       return BN(EntryPrice)
  //         .minus(markPrice)
  //         .dividedBy(EntryPrice)
  //         .multipliedBy(fromWei(position.size.toString(), decimals).toString())
  //         .toString()
  //     }
  //   } else {
  //     if (position.long0) {
  //       return BN(1)
  //         .div(markPrice)
  //         .minus(BN(1).div(EntryPrice))
  //         .dividedBy(BN(1).div(EntryPrice))
  //         .multipliedBy(fromWei(position.size.toString(), decimals).toString())
  //         .toString()
  //     } else {
  //       return BN(1)
  //         .div(EntryPrice)
  //         .minus(BN(1).div(markPrice))
  //         .dividedBy(BN(1).div(EntryPrice))
  //         .multipliedBy(fromWei(position.size.toString(), decimals).toString())
  //         .toString()
  //     }
  //   }
  // }, [EntryPrice, decimals, isToken0, markPrice, position])

  const profitDelta = useMemo(() => {
    const amount = inputAmount.amount?.numerator.toString()
    if (!amount || !position || amount == '0' || !estimateDecrease) return
    const hasProfit = estimateDecrease.hasProfit
    const rsValue = fromWei(estimateDecrease.profitDelta.toString(), decimals).toString()
    if (hasProfit) return `+${rsValue}`
    return `-${rsValue}`
  }, [decimals, estimateDecrease, inputAmount.amount?.numerator, position])

  const closePrice = useMemo(() => {
    if (!estimateDecrease) return
    const price0 = fromSqrt96Wei(estimateDecrease.closePrice.toString(), token0?.decimals, token1?.decimals).toNumber()
    return isToken0 ? price0 : 1 / price0
  }, [estimateDecrease, isToken0, token0?.decimals, token1?.decimals])

  const slippageValue = useMemo(() => {
    if (!estimateDecrease) return
    return (estimateDecrease.closeSpread / 1000000) * 100
  }, [estimateDecrease])

  const netSize = useMemo(() => {
    const amount = inputAmount.amount?.numerator.toString()
    if (!amount || !position || amount == '0' || !estimateDecrease) return
    if (BN(amount).gte(position.size.toString())) {
      return '0'
    } else {
      return fromWei(estimateDecrease.size.toString(), decimals).toString()
    }
  }, [decimals, estimateDecrease, inputAmount.amount?.numerator, position])

  const netCollateral = useMemo(() => {
    const amount = inputAmount.amount?.numerator.toString()
    if (!amount || !position || !isCheck_leverage || !leverage || amount == '0' || !estimateDecreaseL) return
    if (BN(amount).gte(position.size.toString())) {
      return '0'
    }
    return fromWei(estimateDecreaseL.collateral.toString(), decimals).toString()
  }, [decimals, estimateDecreaseL, inputAmount.amount?.numerator, isCheck_leverage, leverage, position])

  const handlePriceChange = (e: any) => {
    setTriggerPrice(e.target.value)
  }

  const init_SLIPPAGE = useAutoSlippageTolerance(undefined)

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="flex-start"
      flexDirection={isSmallScreen ? 'column' : 'row'}
      gap={isSmallScreen ? '16px' : '24px'}
      mt="16px"
    >
      <CloseWrapper>
        <AutoColumn gap="sm">
          <Row width="auto">
            <TradeTypes tabPeriod={tabPeriod} setTabPeriod={setTabPeriod} />
          </Row>
          <CloseSwapSection>
            <SwapCurrencyInputPanel
              label={<Trans>Close</Trans>}
              value={inputAmount.value}
              showMaxButton={false}
              currency={currencyA}
              showMaxTab={true}
              onMaxTab={handleMaxTab}
              renderBalance={() => {
                return <Trans>Balance: {formatCurrencyAmount(currentAmount, NumberType.TokenNonTx)}</Trans>
              }}
              id="cclose-input"
              onUserInput={handleUserInput}
              fiatValue={{ data: Number(inputUsdPrice || 0), isLoading: false }}
            />
          </CloseSwapSection>

          {tabPeriod == TabPeriod.Limit && (
            <>
              <EditSwapSection>
                <EditFeeBox>
                  <ThemedText.TextSecondary fontSize={14}>Trigger Price</ThemedText.TextSecondary>
                  <RowBetween>
                    <StyledInput
                      type="number"
                      className="price-input"
                      defaultValue={triggerPrice}
                      value={triggerPrice}
                      onChange={handlePriceChange}
                      align="left"
                      placeholder="0"
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                    />
                    <Box display="flex " alignItems="center">
                      <DoubleCurrencyLogo
                        size={32}
                        margin
                        currency0={UIistoken0 ? currency1 ?? undefined : currency0 ?? undefined}
                        currency1={!UIistoken0 ? currency1 ?? undefined : currency0 ?? undefined}
                      />
                      <Column gap="xs" align="flex-start" ml="8px">
                        <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                          {UIistoken0
                            ? `${currency0?.symbol}/${currency1?.symbol} `
                            : `${currency1?.symbol}/${currency0?.symbol} `}
                        </ThemedText.TextPrimary>
                      </Column>
                    </Box>
                  </RowBetween>
                </EditFeeBox>
              </EditSwapSection>
              <ThemedText.TextSecondary fontSize={14} mt="8px">
                Fees
              </ThemedText.TextSecondary>
              <EditSwapSection>
                <SwapCurrencyInputPanel
                  label={<Trans>Fees</Trans>}
                  disabled={false}
                  currency={feeCurrency}
                  value={feeamount.value}
                  showMaxButton={true}
                  id="edit-limit-input"
                  onUserInput={setfeeAmount}
                  loading={false}
                  showMaxTab={false}
                  // onMaxTab={handleAmountChange}
                />
              </EditSwapSection>
            </>
          )}
          <Column gap="14px" mt="6px">
            <CheckWapper>
              <Checkbox
                isChecked={isCheck_leverage}
                setIsChecked={setIsCheck_leverage}
                content={`Keep leverage at ${leverage}x`}
              />
            </CheckWapper>
            {/* {tabPeriod == TabPeriod.Market && (
              <CheckWapper>
                <Checkbox
                  isChecked={isCheck_slippage}
                  setIsChecked={setIsCheck_slippage}
                  content="Allow up to 1% slippage"
                />
              </CheckWapper>
            )} */}
          </Column>
          <RowBetween className="infoItem border-bottom">
            <ThemedText.TextSecondary fontSize={14}>Max Slippage</ThemedText.TextSecondary>
            <Row width="auto" align="center" className="pointer">
              <ThemedText.TextPrimary fontWeight={700} mr="8px">
                {slippageTolerance}%
              </ThemedText.TextPrimary>
              <SettingsTab autoSlippage={init_SLIPPAGE} chainId={chainId} trade={undefined} />
            </Row>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Close Price</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={500}>
                {estimateDecreaseLoading ? <LoadingDataBubble /> : countZeros(closePrice)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
            </Row>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Mark Price</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              <ThemedText.TextPrimary fontWeight={500}>{countZeros(markPrice)}</ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
            </Row>
          </RowBetween>
          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Spread</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              {estimateDecreaseLoading ? (
                <LoadingDataBubble />
              ) : (
                <ThemedText.TextPrimary fontWeight={500}>{countZeros(slippageValue)}%</ThemedText.TextPrimary>
              )}
            </Row>
          </RowBetween>

          <RowBetween>
            <ThemedText.TextSecondary fontSize={14}>Est.Receive</ThemedText.TextSecondary>
            <Row justify="flex-end" flex={1} gap="4px">
              {estimateDecreaseLoading ? (
                <LoadingDataBubble />
              ) : (
                <>
                  <ThemedText.TextPrimary fontWeight={500}>
                    {Receive?.calcReceive || 0} {isShowEthSwitch ? (receiveETH ? 'ETH' : 'WETH') : token?.symbol}
                  </ThemedText.TextPrimary>
                  <ThemedText.TextSecondary fontSize={12}>
                    {Receive?.currentReceive || 0} {calctoken?.symbol}
                  </ThemedText.TextSecondary>
                </>
              )}
            </Row>
          </RowBetween>
          {/* {isShowEthSwitch && (
            <RowBetween>
              <ThemedText.TextPrimary>
                <Trans>Collect as </Trans>
              </ThemedText.TextPrimary>
              <RateToggle4
                isSorted={receiveETH}
                handleRateToggle={() => {
                  setReceiveETH((receiveETH) => !receiveETH)
                }}
              />
            </RowBetween>
          )} */}

          <DynamicApprove />
          <ButtonConfirmed
            data-testid="confirm-trade-button"
            id="confirm-trade-button"
            mt="8px"
            disabled={!isInputValid || (tabPeriod == TabPeriod.Limit && !isApproved)}
            onClick={handlerClose}
          >
            <ThemedText.HeadlineSmall color="accentTextLightPrimary">
              <Trans>{isInputValid ? 'Close' : inputError}</Trans>
            </ThemedText.HeadlineSmall>
          </ButtonConfirmed>
        </AutoColumn>
      </CloseWrapper>
      <CloseInfo>
        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>Entry Price</ThemedText.TextSecondary>

          <Row justify="flex-end" flex={1} gap="4px">
            <ThemedText.TextPrimary fontWeight={500}>{countZeros(EntryPrice)}</ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
          </Row>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-solid">
          <ThemedText.TextSecondary fontSize={14}>Liq. Price</ThemedText.TextSecondary>

          <Row justify="flex-end" flex={1} gap="4px">
            <ThemedText.TextPrimary fontWeight={500}>
              {estimateDecreaseLoading ? (
                <LoadingDataBubble />
              ) : NextLiqPrice && NextLiqPrice != '0' ? (
                <Row>
                  <ThemedText.TextSecondary fontSize={12}>{countZeros(LiqPrice)}</ThemedText.TextSecondary>
                  &nbsp;→ {countZeros(NextLiqPrice)}
                </Row>
              ) : (
                countZeros(LiqPrice)
              )}
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={12}>{symbol}</ThemedText.TextSecondary>
          </Row>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-dashed" mt="8px">
          <ThemedText.TextSecondary fontSize={14}>Size</ThemedText.TextSecondary>

          <Row justify="flex-end" flex={1} gap="4px">
            <ThemedText.TextPrimary fontWeight={500}>
              {netSize ? (
                <Row>
                  <ThemedText.TextSecondary fontSize={12}>
                    {countZeros(fromWei(position?.size?.toString() || 0, decimals).toFixed())}
                  </ThemedText.TextSecondary>
                  &nbsp;→ {countZeros(netSize)}
                </Row>
              ) : (
                countZeros(fromWei(position?.size?.toString() || 0, decimals).toFixed())
              )}
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontWeight={500}>
              {position?.long0 ? token1?.symbol : token0?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>Collateral</ThemedText.TextSecondary>
          <Row justify="flex-end" flex={1} gap="4px">
            <ThemedText.TextPrimary fontWeight={500}>
              {netCollateral ? (
                <Row>
                  <ThemedText.TextSecondary fontSize={12}>{countZeros(initialCollateral)}</ThemedText.TextSecondary>
                  &nbsp;→ {countZeros(netCollateral)}
                </Row>
              ) : (
                countZeros(initialCollateral)
              )}
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontWeight={500}>
              {position?.long0 ? token1?.symbol : token0?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-solid">
          <ThemedText.TextSecondary fontSize={14}>Leverage</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={500}>{leverage}x</ThemedText.TextPrimary>
        </RowBetween>

        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>PnL</ThemedText.TextSecondary>
          <Row justify="flex-end" flex={1} gap="4px">
            <ThemedText.TextPrimary fontWeight={500}>
              {' '}
              {estimateDecreaseLoading ? <LoadingDataBubble /> : profitDelta ? countZeros(profitDelta) : 0}
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontWeight={500}>
              {position?.long0 ? token1?.symbol : token0?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>Borrow Fee</ThemedText.TextSecondary>
          <Row justify="flex-end" flex={1} gap="4px">
            <ThemedText.TextPrimary fontWeight={500}>-{countZeros(netValueFees?.borrowFee)}</ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontWeight={500}>
              {position?.long0 ? token1?.symbol : token0?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        </RowBetween>
        {/* <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>CloseSpread</ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={500}>{Number(position?.closeSpread) / 10 ** 4}%</ThemedText.TextPrimary>
        </RowBetween> */}
        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>Open Fee</ThemedText.TextSecondary>
          <Row justify="flex-end" flex={1} gap="4px">
            <ThemedText.TextPrimary fontWeight={500}>-{countZeros(netValueFees?.Positionfees)}</ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontWeight={500}>
              {position?.long0 ? token1?.symbol : token0?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        </RowBetween>
        <RowBetween className="infoItem border-bottom border-dashed">
          <ThemedText.TextSecondary fontSize={14}>Close Fee</ThemedText.TextSecondary>
          <Row justify="flex-end" flex={1} gap="4px">
            <ThemedText.TextPrimary fontWeight={500}>-{countZeros(netValueFees?.Positionfees)}</ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontWeight={500}>
              {position?.long0 ? token1?.symbol : token0?.symbol}
            </ThemedText.TextPrimary>
          </Row>
        </RowBetween>
      </CloseInfo>
    </Box>
  )
}

export default function ClosePositionModal({ isOpen, onDismiss, position }: ClosePositionModalProps) {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const onModalDismiss = useCallback(() => {
    onDismiss()
    setTxHash('')
    setTxError('')
    setAttemptingTxn(false)
  }, [onDismiss])

  const modalContent = (
    <ConfirmationModalContent2222
      onDismiss={onModalDismiss}
      title={<Trans>Close </Trans>}
      topContent={
        <ModalContent
          position={position}
          setAttemptingTxn={setAttemptingTxn}
          setTxHash={setTxHash}
          setTxError={setTxError}
        />
      }
    />
  )

  return (
    <>
      <TransactionConfirmationModal22222
        isOpen={isOpen}
        onDismiss={onModalDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        txError={txError}
        pendingText={pendingText}
        width="800px"
        reviewContent={modalContent}
      />
    </>
  )
}
