import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { FeeAmount } from '@uniswap/v3-sdk'
import { ButtonConfirmed } from 'components/Button'
import Column from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { useDynamicApprove } from 'components/DynamicApprove'
import LiquidityChartRangeInput from 'components/LiquidityChartRangeInput'
import Row, { RowBetween } from 'components/Row'
import { ConfirmationModalContent, TransactionConfirmationModal22222 } from 'components/TransactionConfirmationModal'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency, useToken } from 'hooks/Tokens'
import { useHypervisor } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { AmountBox, BoxCard } from 'pages/Lock/LockModal/CreateLockModal/CreateLockModal'
import { ModalTabCard } from 'pages/Lock/LockModal/ManageModal'
import { TokenInfo } from 'pages/Vote/types'
import { useCallback, useMemo, useReducer, useState } from 'react'
import { Bound } from 'state/mint/v3/actions'
import { useV3DerivedMintInfo } from 'state/mint/v3/hooks'
import { Field } from 'state/swap/actions'
import { useSwapActionHandlers } from 'state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from 'state/swap/reducer'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { ThemedText } from 'theme'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'
import { getTickToPrice } from 'utils/getTickToPrice'

import { useAddPoolLiquidity, useSharsAmount } from './Hooks'

interface FarmPoolProps {
  poolAddress?: string
  isOpen: boolean
  onDismiss: () => void
  token0?: TokenInfo
  token1?: TokenInfo
  fee?: string
  hypervisorAddr?: string
}

export default function FarmPoolModal({ isOpen, onDismiss, token0, token1, hypervisorAddr }: FarmPoolProps) {
  const [curr, setCurr] = useState<any>(0)
  const tabList = ['Add', 'Remove']
  const { account } = useActiveChainId()
  const token0Add = useCurrency(token0?.id)
  const token1Add = useCurrency(token1?.id)
  const addlpToken = useCurrency(hypervisorAddr)

  const sharedAmount = useSharsAmount(hypervisorAddr, token0Add ?? undefined, token1Add ?? undefined)
  const prefilledState: Partial<SwapState> = {
    [Field.INPUT]: {
      currencyId: token0?.id,
    },
    [Field.OUTPUT]: {
      currencyId: token1?.id,
    },
  }

  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })

  const { typedValue, independentField } = state

  const { onUserInput } = useSwapActionHandlers(dispatch)
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const hypervisorContract = useHypervisor(hypervisorAddr)

  const { parsedAmount, inputError, outAmount } = useAddPoolLiquidity(state, hypervisorAddr, token0Add, token1Add)

  const parsedAmounts = useMemo(
    () => ({
      [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : outAmount,
      [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : outAmount,
    }),
    [independentField, outAmount, parsedAmount]
  )

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: parsedAmounts[dependentField]?.toExact(),
    }),
    [dependentField, independentField, parsedAmounts, typedValue]
  )

  const handleAmount0Change = useCallback(
    (amount: string) => {
      onUserInput(Field.INPUT, amount)
    },
    [onUserInput]
  )
  const handleAmount1Change = useCallback(
    (amount: string) => {
      onUserInput(Field.OUTPUT, amount)
    },
    [onUserInput]
  )

  const {
    inputAmount: amountlp,
    handleUserInput: setAmountlp,
    isInputValid,
    inputError: LPinputError,
    handleResetInput,
  } = useInputAmount(addlpToken)

  const handleAmountlpChange = useCallback(
    (amount: string) => {
      setAmountlp(amount)
    },
    [setAmountlp]
  )

  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const lpBalance = useCurrencyBalance(account ?? undefined, addlpToken ?? undefined)
  const pendingText = 'pending ...'

  const addTransaction = useTransactionAdder()

  const handleDismissConfirmation = () => {
    onDismiss && onDismiss()
    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash('')
      setTxError('')
      onUserInput(Field.INPUT, '')
      handleResetInput()
    }, 300)
  }

  const depositAndStake = useCallback(async () => {
    const amount00 = parsedAmounts?.[Field.INPUT]?.numerator.toString()
    const amount11 = parsedAmounts?.[Field.OUTPUT]?.numerator.toString()
    if (!amount00 || !amount11 || !hypervisorContract || !account) return
    setAttemptingTxn(true)
    try {
      const response = await hypervisorContract.deposit(amount00, amount11, account, account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.DEPOSIT,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('create Lock error', error)
    }
  }, [account, addTransaction, hypervisorContract, parsedAmounts])

  const rest = (s: string) => {
    console.log('s', s)
  }

  const withdrawAndUnstake = useCallback(async () => {
    const amountlpp = amountlp?.amount?.numerator.toString()
    if (!account || !hypervisorContract || !amountlpp) return
    setAttemptingTxn(true)
    try {
      const response = await hypervisorContract.withdraw(amountlpp, account, account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.WITHDRAW,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('create Lock error', error)
    }
  }, [account, addTransaction, amountlp?.amount?.numerator, hypervisorContract])

  const { DynamicApprove, isApproved } = useDynamicApprove(
    [parsedAmounts[Field.INPUT], parsedAmounts[Field.OUTPUT]],
    hypervisorContract?.address
  )

  const { price, invertPrice, ticksAtLimit } = useV3DerivedMintInfo(
    token0Add ?? undefined,
    token1Add ?? undefined,
    FeeAmount.MEDIUM ?? undefined,
    token0Add ?? undefined
  )

  const { result: baseLower } = useSingleCallResult(hypervisorContract, 'baseLower')
  const { result: baseUpper } = useSingleCallResult(hypervisorContract, 'baseUpper')

  const Token0 = useToken(token0?.id)
  const Token1 = useToken(token1?.id)
  const pricesAtTicks = useMemo(() => {
    if (!baseLower || !baseUpper)
      return {
        [Bound.LOWER]: undefined,
        [Bound.UPPER]: undefined,
      }

    if (baseLower[0] == baseUpper[0] && baseUpper[0] == 0) {
      return {
        [Bound.LOWER]: undefined,
        [Bound.UPPER]: undefined,
      }
    }

    return {
      [Bound.LOWER]: getTickToPrice(Token0 ?? undefined, Token1 ?? undefined, baseLower[0] ?? undefined),
      [Bound.UPPER]: getTickToPrice(Token0 ?? undefined, Token1 ?? undefined, baseUpper[0] ?? undefined),
    }
  }, [Token0, Token1, baseLower, baseUpper])
  const { [Bound.LOWER]: lowerPrice, [Bound.UPPER]: upperPrice } = pricesAtTicks

  const shareAmounts = useMemo(() => {
    if (!sharedAmount || !lpBalance || !amountlp || !amountlp.amount) return
    if (Number(amountlp.value) === 0 || Number(lpBalance.toExact()) === 0) return

    const raito = amountlp.amount.divide(lpBalance)
    let amount0
    let amount1
    if (raito.greaterThan(1)) {
      amount0 = sharedAmount.amount0.multiply(1)
      amount1 = sharedAmount.amount1.multiply(1)
    } else {
      amount0 = sharedAmount.amount0.multiply(raito)
      amount1 = sharedAmount.amount1.multiply(raito)
    }

    return {
      amount0,
      amount1,
    }
  }, [amountlp, lpBalance, sharedAmount])

  function ModalContent() {
    return (
      <>
        <Box mt="8px" width="100%">
          <ModalTabCard>
            {tabList?.map((tab: any, i: number) => (
              <Row
                justify="center"
                className={`tabItem ${curr == i && 'tabItemActive'}`}
                key={i}
                onClick={() => setCurr(i)}
              >
                {curr == i ? (
                  <ThemedText.BodySmall fontWeight={700} color="textAddButton" className="">
                    {tab}
                  </ThemedText.BodySmall>
                ) : (
                  <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                    {tab}
                  </ThemedText.TextSecondary>
                )}
              </Row>
            ))}
          </ModalTabCard>
        </Box>
        {curr == 0 ? (
          <Column gap="sm" align="flex-start">
            <ThemedText.TextSecondary fontSize={14} mt="16px">
              <Trans>Liquidity ranges are automatically rebalanced when certain rebalance triggers are met.</Trans>
            </ThemedText.TextSecondary>
            {lowerPrice && upperPrice && (
              <LiquidityChartRangeInput
                currencyA={token0Add ?? undefined}
                currencyB={token1Add ?? undefined}
                feeAmount={FeeAmount.MEDIUM}
                ticksAtLimit={ticksAtLimit}
                price={price ? parseFloat((invertPrice ? price.invert() : price).toSignificant(8)) : undefined}
                priceLower={lowerPrice}
                priceUpper={upperPrice}
                interactive={!true}
                onLeftRangeInput={rest}
                onRightRangeInput={rest}
              />
            )}
            <AmountBox>
              <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                <Trans>Deposit Amounts</Trans>
              </ThemedText.TextSecondary>
              <BoxCard>
                <Box p=" 0 8px" pb="12px">
                  <SwapCurrencyInputPanel
                    label={<Trans>From</Trans>}
                    value={formattedAmounts[Field.INPUT] ?? ''}
                    showMaxButton={true}
                    currency={token0Add}
                    id="farmAmount"
                    onUserInput={handleAmount0Change}
                    fiatValue={{ data: Number(0), isLoading: false }}
                    loading={false}
                    onMaxTab={handleAmount0Change}
                  />
                </Box>
              </BoxCard>
              <BoxCard>
                <Box p=" 0 8px" pb="12px">
                  <SwapCurrencyInputPanel
                    label={<Trans>From</Trans>}
                    value={formattedAmounts[Field.OUTPUT] ?? ''}
                    showMaxButton={true}
                    currency={token1Add}
                    id="farmAmount"
                    onUserInput={handleAmount1Change}
                    fiatValue={{ data: Number(0), isLoading: false }}
                    loading={false}
                    onMaxTab={handleAmount1Change}
                  />
                </Box>
              </BoxCard>
            </AmountBox>
            <DynamicApprove />
            <ButtonConfirmed disabled={!!inputError || !isApproved} onClick={depositAndStake}>
              {inputError ? inputError : 'Add'}
            </ButtonConfirmed>
          </Column>
        ) : (
          <Column gap="sm" align="flex-start">
            <Box mt="8px" width="100%">
              <AmountBox>
                <ThemedText.TextSecondary fontWeight={700} fontSize={14}>
                  <Trans>Amounts</Trans>
                </ThemedText.TextSecondary>
                <BoxCard>
                  <Box p=" 0 8px" pb="12px">
                    <SwapCurrencyInputPanel
                      label={<Trans>From</Trans>}
                      value={amountlp.value}
                      showMaxButton={false}
                      currency={addlpToken}
                      id="farmAmount"
                      onUserInput={handleAmountlpChange}
                      fiatValue={{ data: Number(amountlp), isLoading: false }}
                      loading={false}
                      onMaxTab={handleAmountlpChange}
                      showMaxTab={true}
                    />
                  </Box>
                </BoxCard>
              </AmountBox>
            </Box>
            <RowBetween gap="sm" className="infoItem" mt="8px">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Pooled {token0?.symbol}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(shareAmounts?.amount0.toSignificant(), 2, true) || 0}
              </ThemedText.TextPrimary>
            </RowBetween>
            <RowBetween gap="sm" className="infoItem">
              <ThemedText.TextSecondary fontSize={14}>
                <Trans>Pooled {token1?.symbol}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(shareAmounts?.amount1.toSignificant(), 2, true) || 0}
              </ThemedText.TextPrimary>
            </RowBetween>
            <ButtonConfirmed onClick={withdrawAndUnstake} disabled={!isInputValid}>
              {LPinputError ? LPinputError : 'Remove'}
            </ButtonConfirmed>
          </Column>
        )}
      </>
    )
  }

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      width="410px"
      pendingText={pendingText}
      reviewContent={
        <ConfirmationModalContent
          title={
            <>
              {token0Add?.symbol}/{token1Add?.symbol} <Trans>Farm Pool</Trans>
            </>
          }
          onDismiss={handleDismissConfirmation}
          topContent={ModalContent}
          gap="0"
        />
      }
    />
  )
}
