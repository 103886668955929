import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  orderBookAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  voterAddress?: string
  rougexTokenAddress?: string
  faucetAddress?: string
  minterAddress?: string
  tradeReaderAddress?: string
  rewardsDistributorAddress?: string
  roxUtilsAddress?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

const ARBITRUM_ONE_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xf26Bd9BA435395F26634C9Be5b717C6d10675897',
  tradeRouter: '0x859997D5686486b023b4fC375f6356FEDf40A923',
  nonfungiblePositionManagerAddress: '0x386d7bE5793B061F01601F606f28E1017Cbc9E68',
  swapRouterAddress: '0xdD84404e9741fB1Ba2f52906B25126fAE22b4243',
  quoterAddress: '0x37e33B250985FAf4DBdc607FA7454CA76B883A2a',
  tickLensAddress: '0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b',

  roxUtilsAddress: '0x9C6C26F5D308B22254a57a87744E0c018BE20dC7',
  tradeReaderAddress: '0xcd16b287536FF7A761B3574066a91e95635481ec',
  orderBookAddress: '0x8e7a5187117F3dfbae028ac26db00A75aE41F095',

  lockAddress: '0xd4c4Ae8172253332F4D5E13aAFC096cF35f33905',

  rougexTokenAddress: '0x05956a4674945DEE1E00442a53c562350282C340',
  rewardsDistributorAddress: '0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21',

  voterAddress: '0x03245900162B798726514d9c3c18c1C8EfF2952f',
  minterAddress: '0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967',

  multicallAddress: '0x027EDCF231957833e95Bf6131E61EA8345d580d3',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

// eslint-disable-next-line multiline-comment-style

// arbitrum goerli v3 addresses
const ARBITRUM_GOERLI_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x0B8CD001Fe3021A4A994dB969CDB30120Af0FBfE',
  tradeRouter: '0x86f6d8b697D43FCf5f83373993Cea4E7F5443b3A',
  nonfungiblePositionManagerAddress: '0xc42A56215a0E3C1d18a0bE08424378b5586fd5fD',
  swapRouterAddress: '0xb5Def17A670C6E9684cc32DF72250aD78B65c5F0',
  quoterAddress: '0x942f0e9F0CB3bBa758EA5e45Cac6E4A1A34F296f',
  tickLensAddress: '0x833E55f050C9FCDB098CFAc2b149Ba323B0955AA',
  roxUtilsAddress: '0x3aB52361ee1320a54be17f7EEEeD156A310b2944',
  tradeReaderAddress: '0xF9B59FD11Eb8c202736C2457D915258D17EeD5B8',
  orderBookAddress: '0x631D2C5d754d20E4d406cCc4a9A2c92B8d17C309',

  multicallAddress: '0x8260CB40247290317a4c062F3542622367F206Ee',

  lockAddress: '0x0cFE2dEbccF54f006E329B51A39E1F1Ea1a8C332',
  rougexTokenAddress: '0xD4759feC74dC19eb68552874bFF5b8C7f643d7Ae',
  rewardsDistributorAddress: '0x2774b48f7B412C18cE8F7c7DedFc20503c9A9bcB',

  voterAddress: '0xD40659f8283c43931E61fcfdfAa705CD21B00B72',
  minterAddress: '0x943f53E8984B07964D0A5eDf167173dEec7353a0',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const Sepolia_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xa55F2744DA15cD95822E8e0958305C58e32F9a98',
  tradeRouter: '0xE84594F5B8740E428405f6928C493ef63A318dbA',
  nonfungiblePositionManagerAddress: '0x0Fe7ACf5a758144536e415540e560650B79F6214',
  swapRouterAddress: '0xf8D61E10845893c12B9C495e01976C43F957b529',
  quoterAddress: '0x86eFaA010f65B17dD7504fA7450d56aEcC77bcAD',
  tickLensAddress: '0x4Eb761FCdEC79825b50d7572AdC02601b453639b',
  roxUtilsAddress: '0x75B801637DBB14E59B6Bf378b558601B7B219057',
  tradeReaderAddress: '0xF18F2234823130C50EeD4736893a328Ab4ab54D0',
  orderBookAddress: '0x06422f49ab551c6D7e1ad30af4B7A80f5712d13d',

  multicallAddress: '0x532a5B1A2F8ccE4f05F8b2a9A262c7097B4356ec',

  lockAddress: '0x48878653673113bD4DbEd485a34E194735BA7E10',
  rougexTokenAddress: '0x00fA700a16eF7B3c968Bb1dCD4C6bA697bA73fb9',
  rewardsDistributorAddress: '0x20B7ab1AD1517Bd17cD2F4dc5C29920Bff92ba82',

  voterAddress: '0x780A9Fa3B434b99A2f7751B00c6fC269f19871Bd',
  minterAddress: '0x918891120A62B0F2a91012a8De9f10Dc30a9E3Cd',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: ARBITRUM_ONE_ADDRESSES,
  [ChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_ADDRESSES,
  [ChainId.ARBITRUM_SEPOLIA]: Sepolia_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MINTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const minterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].minterAddress
    if (minterAddress) {
      memo[chainId] = minterAddress
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}

export const FAUCET_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const faucetAddress = CHAIN_TO_ADDRESSES_MAP[chainId].faucetAddress
    if (faucetAddress) {
      memo[chainId] = faucetAddress
    }
    return memo
  }, {}),
}
export const TRADEREADER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeReaderAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tradeReaderAddress
    if (tradeReaderAddress) {
      memo[chainId] = tradeReaderAddress
    }
    return memo
  }, {}),
}

export const REWARDS_DISTRIBUTOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rewardsDistributorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rewardsDistributorAddress
    if (rewardsDistributorAddress) {
      memo[chainId] = rewardsDistributorAddress
    }
    return memo
  }, {}),
}

export const ROX_UTILS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const roxUtilsAddress = CHAIN_TO_ADDRESSES_MAP[chainId].roxUtilsAddress
    if (roxUtilsAddress) {
      memo[chainId] = roxUtilsAddress
    }
    return memo
  }, {}),
}

export const ORDER_BOOK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].orderBookAddress
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
