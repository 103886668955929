import { useActiveChainId } from 'connection/useActiveChainId'
import { ChainId } from 'constants/chains'
import { useEffect } from 'react'
import { useDarkModeManager } from 'theme/components/ThemeToggle'

const initialStyles = {
  width: '200vw',
  height: '200vh',
  transform: 'translate(-50vw, -100vh)',
}
const backgroundResetStyles = {
  width: '100vw',
  height: '100vh',
  transform: 'unset',
}

type TargetBackgroundStyles = typeof initialStyles | typeof backgroundResetStyles

const backgroundRadialGradientElement = document.getElementById('background-radial-gradient')
const setBackground = (newValues: TargetBackgroundStyles) =>
  Object.entries(newValues).forEach(([key, value]) => {
    if (backgroundRadialGradientElement) {
      backgroundRadialGradientElement.style[key as keyof typeof backgroundResetStyles] = value
    }
  })

export default function RadialGradientByChainUpdater(): null {
  const { chainId } = useActiveChainId()
  const [darkMode] = useDarkModeManager()

  // manage background color
  useEffect(() => {
    if (!backgroundRadialGradientElement) {
      return
    }
    switch (chainId) {
      case ChainId.ARBITRUM_ONE:
      case ChainId.ARBITRUM_GOERLI: {
        setBackground(backgroundResetStyles)
        const arbitrumLightGradient = 'radial-gradient(50% 50% at 50% 50%, #78D6F1 0%, #F5F5F5 100%)'
        const arbitrumDarkGradient = 'radial-gradient(50% 50% at 50% 50%, #055972 0%, #001E27 100%)'
        backgroundRadialGradientElement.style.background = darkMode ? arbitrumDarkGradient : arbitrumLightGradient
        break
      }
      default: {
        setBackground(initialStyles)
        const defaultLightGradient = 'radial-gradient(50% 50% at 50% 50%, #78D6F1 0%, #F5F5F5 100%)'
        const defaultDarkGradient = 'radial-gradient(50% 50% at 50% 50%, #055972 0%, #001E27 100%)'
        backgroundRadialGradientElement.style.background = darkMode ? defaultDarkGradient : defaultLightGradient
      }
    }
  }, [darkMode, chainId])
  return null
}
