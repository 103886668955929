import { useQuery } from '@apollo/client'
import { Percent, Token } from '@uniswap/sdk-core'
import { useActiveChainId } from 'connection/useActiveChainId'
import { PollingInterval, usePollQueryWhileMounted } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { BN } from 'utils/bn'
const query = gql`
  {
    bundles {
      ethPriceUSD
    }
    pools (orderBy:totalValueLockedUSD,orderDirection:desc) {
      id
      totalValueLockedUSD
      tradePool
      feeTier
      sqrtPrice
      sizeLong
      sizeShort
      token0Price
      poolDayData(first: 1, orderBy: date, orderDirection: desc) {
        close
      }
      token0 {
        id
        decimals
        symbol
        name
        derivedETH
      }
      token1 {
        id
        decimals
        symbol
        name
        derivedETH
      }
    }
  }
`

interface TokenInfo {
  id: string
  decimals: number
  symbol: string
  name: string
  derivedETH: string
}

interface GraphqlPool {
  id: string
  totalValueLockedUSD:string
  tradePool: string
  feeTier: string
  token0: TokenInfo
  token1: TokenInfo
  sqrtPrice: string
  sizeLong: string
  sizeShort: string
  token0Price: string
  poolDayData: {
    close: string
  }[]
}

interface AllPool {
  bundles: {
    ethPriceUSD: string
  }[]
  pools: GraphqlPool[]
}
export interface AllPoolsProps {
  token0: Token
  token1: Token
  id: string
  fee: Percent
  feeTier: any
  sqrtPrice: string
  ethPriceUSD: string
  change: string
  tradePool: string
  sizeLong: string
  sizeShort: string
  totalValueLockedUSD:string
}
const useQueryAllPools = () => {
  const {
    data,
    loading: isLoading,
    error,
  } = usePollQueryWhileMounted(useQuery(query), PollingInterval.LightningMcQueen)
  return useMemo(
    () => ({
      error,
      isLoading,
      data: data as AllPool | undefined,
    }),
    [data, error, isLoading]
  )
}

export const useAllPools = () => {
  const { chainId } = useActiveChainId()

  const { data: pools } = useQueryAllPools()
  return useMemo(() => {
    if (!chainId || !pools) return
    const ethPriceUSD = pools.bundles[0]?.ethPriceUSD
    const temp = pools?.pools.reduce<{ [key: string]: AllPoolsProps }>((acc, pool) => {
      //sizeLong decimal token1
      //sizeShort decimal token0
      console.log(acc)
      const sizeShortTo1 =
        Number(pool.sizeShort) <= 0 || Number(pool.token0Price) == 0
          ? 0
          : (1 / Number(pool.token0Price)) * Number(pool.sizeShort)
      // token0 price = 1/pool.token0Price
      const iszero = Number(pool.token0Price) === 0 || Number(pool.poolDayData?.[0].close) === 0
      const changes = BN(pool.token0Price)
        .minus(pool.poolDayData?.[0].close)
        .div(pool.poolDayData?.[0].close)
        .times(100)

      acc[pool.id] = {
        totalValueLockedUSD:pool.totalValueLockedUSD,
        token0: new Token(chainId, pool.token0.id, Number(pool.token0.decimals), pool.token0.symbol, pool.token0.name),
        token1: new Token(chainId, pool.token1.id, Number(pool.token1.decimals), pool.token1.symbol, pool.token1.name),
        id: pool.id,
        sqrtPrice: pool.sqrtPrice,
        ethPriceUSD,
        fee: new Percent(pool.feeTier, 1_000_000),
        feeTier: Number(pool.feeTier),
        tradePool: pool.tradePool,
        sizeLong: Number(pool.sizeLong) <= 0 ? '0' : BN(pool.sizeLong).toString(),
        sizeShort: sizeShortTo1.toString(),
        change: iszero ? '0.00' : changes.isNaN() ? '0.00' : changes.toFixed(2),
      }
      return acc
    }, {})
    return temp
    // return   temp.sort((a.p,b)=>Number(a.totalValueLockedUSD)-Number(b.totalValueLockedUSD))
  }, [chainId, pools])
}
